import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import useWindowSize from "../../hooks/use-window-size"
import { ReactComponent as GreenFigure } from "../../assets/svg/welcome-figure.svg"
import { ReactComponent as PurpleCog } from "../../assets/svg/purple-cog.svg"
import { ReactComponent as RedCog } from "../../assets/svg/red-cog.svg"
import { ReactComponent as BlueCog } from "../../assets/svg/blue-cog.svg"

import { Container, Main, Content, Body } from "./elements"

import { Flex, Box, Image, Text } from "rebass"

import "./layout.css"
import SEO from "../seo"
import Logo from "../../assets/svg/new-logo.svg"
import styled from "@emotion/styled"

const StyledDivider = styled(Box)`
  background: #000000cc;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  margin: 0 5px;
`

const Layout = ({ title, children }) => {
  const { width, height } = useWindowSize()

  return (
    <Container>
      <Main>
        <Content fontFamily={"body"} p={4}>
          <Body p={3} mx="auto">
            <SEO title={title} />
            <Flex width="100%" height="100%" flexDirection="column">
              <Flex
                sx={{
                  position: "absolute",
                  left: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <GreenFigure />
              </Flex>
              <Flex
                sx={{
                  position: "absolute",
                  top: "40%",
                  left: "80%",
                }}
              >
                <PurpleCog />
              </Flex>
              <Flex
                sx={{
                  position: "absolute",
                  top: "20%",
                  left: "40%",
                }}
              >
                <BlueCog />
              </Flex>
              <Flex
                sx={{
                  position: "absolute",
                  bottom: "20%",
                  left: "25%",
                }}
              >
                <RedCog />
              </Flex>
              <Flex alignItems="center" justifyContent="center" flexGrow={1}>
                <Image height="34px" src={Logo} />
              </Flex>
              <Flex
                backgroundColor="#fefefe"
                width={5 / 12}
                sx={{ zIndex: 9999 }}
                flexDirection="column"
                mx="auto"
                flexGrow={1}
                my={4}
              >
                <Flex
                  sx={{ flexDirection: "column" }}
                  variant={"loginCard"}
                  p={5}
                >
                  {children}
                </Flex>
              </Flex>
              <Flex alignItems="center" justifyContent="center">
                <Text fontSize={1}>© Medusa Commerce</Text>
                <StyledDivider />
                <Text fontSize={1}>Contact</Text>
                <StyledDivider />
                <Text fontSize={1}>Privacy & Terms</Text>
              </Flex>
            </Flex>
          </Body>
        </Content>
      </Main>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
